<template>
  <b-modal
    id="modal-manual-update"
    ref="my-modal"
    title="Update Work Element"
    centered
    no-fade
    hide-backdrop
    static
    size="lg"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        Update Work Element
      </h5>
      <div class="modal-actions">
        <b-button
          variant="outline-primary"
          @click="hideModal"
        >
          <feather-icon
            icon="XIcon"
            size="18"
          />
        </b-button>
      </div>
    </template>
    <div class="form-group">
      <div class="row">
        <div class="col">
          <label>Load E</label>
          <b-form-input
            v-model="loadEngageData"
            type="number"
            :disabled="true"
          />
        </div>
        <div class="col">
          <label>Fte E</label>
          <b-form-input
            v-model="fteEngageData"
            type="number"
            :disabled="true"
          />
        </div>
        <div class="col">
          <label>Spent</label>
          <b-form-input
            v-model="spentData"
            type="number"
            :disabled="true"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label>Load R/E</label>
          <b-form-input
            v-model="loadEstimatedData"
            type="number"
            :disabled="true"
          />
        </div>
        <div class="col">
          <label>Fte R/E</label>
          <b-form-input
            v-model="fteEstimatedData"
            type="number"
            :disabled="true"
          />
        </div>
        <div class="col">
          <label>% acc R/E</label>
          <b-form-input
            v-model="accEstimatedData"
            type="number"
            :disabled="true"
          />
        </div>
        <div class="col">
          <label>Rest To Do R/E</label>
          <b-form-input
            v-model="restEstimatedData"
            type="number"
            :disabled="true"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label>Spent R/E</label>
          <b-form-input
            v-model="spentNewEstimatedData"
            type="number"
            @input="handleChange"
          />
        </div>
        <div class="col">
          <label>Fte R/E</label>
          <b-form-input
            v-model="fteNewEstimatedData"
            type="number"
            @input="handleChange"
          />
        </div>
        <div class="col">
          <label>% acc R/E</label>
          <b-form-input
            v-model="accNewEstimatedData"
            aria-describedby="input-live-help"
            type="number"
            :disabled="isAcc === 1"
            @input="handleChangeAccORest(1)"
          />
          <b-form-text
            id="input-live-help"
            class="muted"
            style="font-size:10px"
          >
            Input only one
          </b-form-text>
        </div>
        <div class="col">
          <label>Rest To Do R/E</label>
          <b-form-input
            v-model="restNewEstimatedData"
            aria-describedby="input-live-help"
            type="number"
            :disabled="isAcc === 2"
            @input="handleChangeAccORest(2)"
          />
          <b-form-text
            id="input-live-help"
            class="muted"
            style="font-size:10px"
          >
            Input only one
          </b-form-text>
        </div>
        <div class="col">
          <b-button
            style="margin-top:20px"
            variant="primary"
            @click="handleCalculate"
          >
            Get Values
          </b-button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label>Start Date R/E</label>
          <b-input-group>
            <b-form-input
              v-model="startDateEstimated"
              type="text"
              placeholder="MM-DD-YYYY"
              autocomplete="off"
              readonly
            ></b-form-input>
            <b-input-group-append style="height:37px">
              <b-form-datepicker
                v-model="selectedStartDate"
                button-only
                button-variant="primary"
                right
                locale="en-US"
              />
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="col">
          <label>Duration reamaining R/E</label>
          <b-form-input
            v-model="durationDateEstimated"
            type="number"
          />
        </div>
        <div class="col">
          <label>End Date R/E</label>
          <b-input-group>
            <b-form-input
              v-model="endDateEstimated"
              type="text"
              placeholder="MM-DD-YYYY"
              autocomplete="off"
              readonly
            ></b-form-input>
            <b-input-group-append style="height:37px">
              <b-form-datepicker
                v-model="selectedEndDate"
                button-only
                button-variant="primary"
                right
                locale="en-US"
              />
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="col">
          <b-button
            style="margin-top:20px"
            variant="primary"
            @click="handleCalculateDate"
          >
            Calculate Date
          </b-button>
        </div>
      </div>
    </div>
    <!-- Modal Footer -->
    <template #modal-footer>
      <b-button
        variant="outline-primary"
        @click="hideModal"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        @click="handleSave"
      >
        Update
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BButton,
  BFormInput,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'
import moment from "moment"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BFormInput,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    selectedWE: {
      type: Object || Number,
      default: () => {},
    },
  },
  data() {
    return {
      loadEstimatedData: 0,
      fteEstimatedData: 0,
      loadEngageData: 0,
      fteEngageDate: 0,
      spentData: 0,
      durationEstimated: 0,
      isAcc: 0,
      spentNewEstimatedData: null,
      fteNewEstimatedData: null,
      accEstimatedData: 0,
      accNewEstimatedData: null,
      restEstimatedData: 0,
      restNewEstimatedData: null,
      startDateEstimated: null,
      endDateEstimated: null,
      selectedStartDate: moment(),
      selectedEndDate: moment(),
      isValid: false,
      isDateValid: false,
      formattedDate: null
    }
  },
  watch: {
      selectedWE: {
        immediate: true,
        handler(newVal) {
          this.initializeData(newVal)
        },
      },
      selectedStartDate: {
        immediate: true,
        handler(newVal) {
          this.startDateEstimated = moment(newVal).format('MM-DD-YYYY')
        },
      },
      selectedEndDate: {
        immediate: true,
        handler(newVal) {
          this.endDateEstimated = moment(newVal).format('MM-DD-YYYY')
        },
      }
  },
  methods: {
    initializeData(newWE) {
      console.log('newwe:', newWE)
      this.loadEngageData = newWE.load_engage
      this.loadEstimatedData = newWE.load_reel
      this.accEstimatedData = newWE.acc
      this.spentData = parseFloat(this.loadEstimatedData) * (parseFloat(this.accEstimatedData) / 100.0)
      this.fteEngageData = newWE.fte_engage
      this.fteEstimatedData = newWE.fte_reel
      this.restEstimatedData = this.loadEstimatedData * (1 - (parseFloat(this.accEstimatedData) / 100.0))
      this.durationEstimated = this.loadEstimatedData / this.fteEstimatedData
      this.spentNewEstimatedData = 0
      this.fteNewEstimatedData = 0
      this.restNewEstimatedData = 0
      this.accNewEstimatedData = 0
      this.durationDateEstimated = 0
      this.selectedStartDate = newWE.start_date_estimated !== null && newWE.start_date_estimated !== undefined ? new Date(newWE.start_date_estimated) : new Date()
      this.selectedEndDate = newWE.end_date_estimated !== null && newWE.end_date_estimated !== undefined ? new Date(newWE.end_date_estimated) : new Date()
      this.isAcc = 0
      this.isValid = false
      this.isDateValid = false
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    async handleSave() {
      const loadNewEstimatedData = this.isAcc === 1 ? parseFloat(this.restNewEstimatedData) + parseFloat(this.spentNewEstimatedData)
        : parseFloat(this.spentNewEstimatedData) / (parseFloat(this.accNewEstimatedData) / 100.0)
      const teams = this.$store.state.globalState.allTeamTitleData.find(team => team.title === this.selectedWE.team_name)
      let teamId = 0
      if (teams !== undefined) teamId = teams.id
      const payloads = {
        we_id: this.selectedWE.id,
        description: this.selectedWE.description,
        title: this.selectedWE.title,
        load_engage: this.selectedWE.load_engage,
        duration_engage: this.selectedWE.duration_engage,
        fte_engage: this.selectedWE.fte_engage,
        gateid: this.selectedWE.gateid,
        priority: this.selectedWE.priority,
        prbg_id: this.selectedWE.prbg_id,
        jobid: this.selectedWE.jobid,
        team_id: teamId,
        detail_mode: false,
        load_estimated: loadNewEstimatedData,
        acc: this.accEstimatedData,
        rest_todo_estimated: this.restNewEstimatedData,
        start_date_reel: this.startDateEstimated,
        end_date_reel: this.endDateEstimated,
        duration_date_reel: this.durationDateEstimated
      }
      if (this.fteNewEstimatedData !== '') {
        payloads.fte_estimated = this.fteNewEstimatedData
      }
      await this.$store.dispatch('globalState/submit_manual_update', payloads)
      await this.$store.dispatch('globalState/load_org_data')
      const data = this.$store.state.globalState.selectedNavObj
      await this.$store.dispatch('globalState/get_from_selected_nav_id', {
        data
      })
      this.$refs['my-modal'].hide()
    },
    async handleCalculateDate() {
      if (this.startDateEstimated === null || this.startDateEstimated === undefined || this.startDateEstimated === '') {
        this.showToast('warning', 'Please select the start date.')
        return
      }
      if (parseFloat(this.fteEstimatedData) === 0) {
        this.showToast('warning', 'Please Input non-zero value for FTE R/E.')
        return
      }
      this.durationDateEstimated = this.fteEstimatedData === 0 ? 0 : parseFloat(this.restEstimatedData) / parseFloat(this.fteEstimatedData)
      const currentDate = moment()
      const endDate = currentDate.add(parseInt(this.durationDateEstimated, 10), 'days')
      this.endDateEstimated = endDate.format('MM-DD-YYYY')
      if (this.startDateEstimated === '' || this.endDateEstimated === '' || this.endDateEstimated === null) {
        this.showToast('warning', 'Invalid Date')
        this.isDateValid = false
      }
    },
    handleChangeAccORest(type) {
      this.isValid = false
      if (type === 1) {
        this.isAcc = 2
        this.restNewEstimatedData = ''
      } else if (type === 2) {
        this.isAcc = 1
        this.accNewEstimatedData = ''
      }
      if (this.accNewEstimatedData === '' && this.restNewEstimatedData === '') this.isAcc = 0
      if (this.accNewEstimatedData === '' && this.isAcc === 2) this.isAcc = 0
      if (this.restNewEstimatedData === '' && this.isAcc === 1) this.isAcc = 0
    },
    handleCalculate() {
      if (this.isAcc === 0) {
        this.showToast('warning', 'Please input at least one for %acc R/E or Rest To Do R/E')
        this.isValid = false
      } else if ((this.isAcc === 1 || this.isAcc === 2) && (this.spentNewEstimatedData === '')) {
        this.showToast('warning', 'Please input spent R/E data')
        this.isValid = false
      } else {
        this.showToast('success', 'All Values are valid')
        if (this.isAcc === 2 && parseFloat(this.accNewEstimatedData) === 0) {
          this.showToast('warning', 'Please Input non-zero for %acc R/E')
          return
        }
        try {
          this.loadEstimatedData = this.isAcc === 2 ? parseFloat(this.spentNewEstimatedData) / (parseFloat(this.accNewEstimatedData) / 100.0) : parseFloat(this.spentNewEstimatedData) + parseFloat(this.restNewEstimatedData)
          this.fteEstimatedData = this.fteNewEstimatedData
          this.spentData = this.spentNewEstimatedData
          this.accEstimatedData = this.isAcc === 2 ? parseFloat(this.accNewEstimatedData).toFixed(2) : (parseFloat(this.spentNewEstimatedData) / parseFloat(this.loadEstimatedData).toFixed(2)).toFixed(4) * 100
          this.restEstimatedData = this.isAcc === 1 ? this.restNewEstimatedData : (parseFloat(this.loadEstimatedData) * (1 - (parseFloat(this.accNewEstimatedData) / 100)))
          this.isValid = true
        } catch (e) {
          this.showToast('warning', 'Invalid Data')
          this.isValid = false
        }
      }
    },
    handleChange() {
      this.isValid = false
    },
    showToast(variant, title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'BellIcon',
          text: null,
          variant,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-portfolio.scss';
@import '@core/scss/vue/pages/dashboard-project.scss';
</style>
